import React from 'react';
import { useDebug } from './debug-provider';

const CenterLineDebug = (): JSX.Element | null => {
    const { showCenter } = useDebug();

    if (showCenter) {
        return (
            <div css={{
                position: 'fixed',
                left: 'calc(50% - 2px)',
                width: '4px',
                height: '100%',
                backgroundColor: 'green',
                top: '0',
                pointerEvents: 'none',
                zIndex: '100',
            }}
            />
        );
    }

    return null;
};

export default CenterLineDebug;
