import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import MediaItem from '../../../primitives/media-item';
import AspectRatio from '../../../primitives/aspect-ratio';
import { borderRadius, breakpoints } from '../../../primitives/tokens';

const MediaBelowTextHero = (props: HeroProps): JSX.Element => {
    const { visual } = props;
    return (
        <div css={{
            position: 'relative',
            marginBottom: '65px',
            [breakpoints.mb]: {
                marginBottom: '50px',
            },
        }}
        >
            <Background
                breakpoints={{
                    dt: { gradient: 'greyAlphaRotated', clip: 'bottom' },
                    mb: { gradient: 'none', clip: 'none' },
                }}
                css={{
                    position: 'absolute',
                    overflow: 'hidden',
                    height: 'calc(100% - 154px)',
                    width: '100%',
                }}
            />

            <Container>
                <Row>
                    <Col breakpoints={{ dt: { span: 12 } }}>
                        <div css={{
                            paddingTop: '135px',
                            paddingBottom: '60px',
                            margin: 'auto',
                            [breakpoints.mb]: {
                                paddingTop: '70px',
                                paddingBottom: '34px',
                            },
                        }}
                        >
                            <Content centered {...props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col breakpoints={{ dt: { span: 12 } }}>
                        <AspectRatio breakpoints={{
                            dt: { width: 1266, height: 597 },
                            mb: { width: 318, height: 173 },
                        }}
                        >
                            <MediaItem
                                css={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: borderRadius.large,
                                    transform: 'translateZ(0)',
                                    overflow: 'hidden',
                                }}
                                item={visual[0].mediaItem}
                            />
                        </AspectRatio>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default MediaBelowTextHero;
