import qs, { ParsedQs } from 'qs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@gatsbyjs/reach-router';

const useQueryParams = (): ParsedQs => {
    const { search } = useLocation();
    return qs.parse((search || '').replace('?', ''));
};

export default useQueryParams;
