/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import {
    isParagraph, isHeading, isList, isListItem, isLink, isBlockquote,
} from 'datocms-structured-text-utils';
import React from 'react';
import { renderNodeRule, StructuredText as StructuredTextRenderer, StructuredTextDocument } from 'react-datocms';
import clsx from 'clsx';
import { useTypographyOverride } from '../primitives/typography';
import StyledLink from '../primitives/styled-link';
import ScrollEffect from '../animations/components/scroll-effect';
import { colors } from '../primitives/colors';
import BlockRenderer from './block-renderer';
import '../../layouts/wysiwyg.sass';
import { PolymorphicWithoutRef } from '../primitives/polymorphic';
import { breakpoints } from '../primitives/tokens';
import Link from '../primitives/link';

const createParagraphRenderer = className => ({ children, key, ancestors }) => {
    // If the paragraph is inside a list item, simply output the text
    if ((ancestors[0] && isListItem(ancestors[0])) || (ancestors[0] && isBlockquote(ancestors[0]))) {
        return children;
    }

    return <p className={className} key={key}>{children}</p>;
};

const createLinkRenderer = (className, isBlog) => ({ node, children, key }) => {
    if (isBlog) {
        return (
            <Link key={key} to={node.url} className={className}>
                {children}
            </Link>
        );
    }

    return (
        <StyledLink
            css={{
                fontSize: 'inherit!important',
                [breakpoints.mb]: {
                    fontSize: 'inherit!important',
                },
                color: colors.primaryOrange,
            }}
            className={className}
            to={node.url}
            key={key}
        >
            {children}
        </StyledLink>
    );
};

const createListRenderer = className => ({ children, key }) => (
    <ul
        key={key}
        className={className}
    >
        {children}
    </ul>
);

const createListItemRenderer = className => ({ children, key }) => (
    <li className={clsx(className, 'spacing')} key={key}>{children}</li>
);

const createBlockquoteRenderer = className => ({ node, children, key }) => (
    <blockquote
        cite={node.attribution}
        key={key}
        className={className}
    >
        <p>{children}</p>
        <footer>
            <cite>{node.attribution}</cite>
        </footer>
    </blockquote>
);

const createHeadingRenderer = className => ({ node, children, key }) => {
    if (node.level === 1) {
        return (
            <h1 className={className} key={key}>{children}</h1>
        );
    }
    if (node.level === 2) {
        return (
            <h2 className={className} key={key}>{children}</h2>
        );
    }
    if (node.level === 3) {
        return (
            <h3 className={className} key={key}>{children}</h3>
        );
    }
    if (node.level === 4) {
        return (
            <h4 className={className} key={key}>{children}</h4>
        );
    }
    if (node.level === 5) {
        return (
            <h5 className={className} key={key}>{children}</h5>
        );
    }
    return (
        <h6 className={className} key={key}>{children}</h6>
    );
};

const createGenericRenderer = (as: React.ElementType) => ({ children }) => (
    <ScrollEffect
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        as={as as React.ElementType}
        inViewProperties={{
            y: [10, 0],
            opacity: [0, 1],
        }}
    >
        {children}
    </ScrollEffect>
);

const createGenericRendererNoAnimate = (as: React.ElementType) => ({ children }) => {
    const Component = as;
    return <Component>{children}</Component>;
};

interface StructuredTextProps {
    data: StructuredTextDocument,
    className?: string,
    isBlog?: boolean
    cleanTags?: boolean
}

const StructuredText = <C extends React.ElementType = 'div'>({
    data,
    className,
    isBlog,
    as,
    cleanTags,
}: PolymorphicWithoutRef<C, StructuredTextProps>): JSX.Element | null => {
    const ctx = useTypographyOverride();
    if (as && !ctx && process.env.NODE_ENV === 'development') {
        throw new Error('Structured Text cannot be polymorphic (as prop) without a Typography Override.');
    }
    if (as && cleanTags && process.env.NODE_ENV === 'development') {
        throw new Error('As prop cannot be used with Clean Tags in Structured Text. Clean Tags will emit default unstyled tags, as will override this.');
    }
    if (!data) return null;
    if (!data?.value) return null;
    const updatedData = {
        ...data,
        blocks: (data.blocks || []).map(block => ({
            ...block,
            id: block.originalId,
        })),
    };
    const Component = as || 'div';
    return (
        <Component
            css={!isBlog ? {
                'i, em': {
                    fontStyle: 'inherit',
                    fontWeight: 'inherit',
                    color: colors.primaryOrange,
                },
                'b, strong': {
                    fontWeight: 500,
                    color: colors.primaryOrange,
                },
            } : {}}
            className={clsx({
                wysiwyg: isBlog,
            })}
        >
            <StructuredTextRenderer
                data={updatedData}
                renderBlock={({ record }) => (
                    <BlockRenderer isBlog={isBlog} data={[record]} />
                )}
                customNodeRules={[
                    renderNodeRule(
                        isParagraph,
                        as ? createGenericRendererNoAnimate('div') : createParagraphRenderer(className),
                    ),
                    renderNodeRule(
                        isLink,
                        createLinkRenderer(className, isBlog),
                    ),
                    renderNodeRule(
                        isList,
                        createListRenderer(className),
                    ),
                    renderNodeRule(
                        isListItem,
                        createListItemRenderer(className),
                    ),
                    renderNodeRule(
                        isBlockquote,
                        createBlockquoteRenderer(className),
                    ),
                    renderNodeRule(
                        isHeading,
                        as ? createGenericRendererNoAnimate('div') : createHeadingRenderer(className),
                    ),
                ]}
            />
        </Component>
    );
};

export default StructuredText;
