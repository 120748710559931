import React, { PropsWithChildren } from 'react';
import Typography from '../../../primitives/typography';
import Container from '../../../primitives/grid/container';

const EmptyBlock = ({ children }: PropsWithChildren): JSX.Element => (
    <Container css={{
        paddingTop: '48px',
        paddingBottom: '48px',
    }}
    >
        <Typography fontSize={{ dt: 1627 }}>
            Block missing:
            {' '}
            {children}
        </Typography>
    </Container>
);

export default EmptyBlock;
