import qs from 'qs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@gatsbyjs/reach-router';

const useQueryData = (to = ''): [string, string, string] => {
    const { search, pathname } = useLocation();
    const queryParts = to.split(/(?=[(?#)])/);

    const path = queryParts.find(x => x[0] !== '?' && x[0] !== '#') || (to !== '' ? pathname : '');
    const query = queryParts.find(x => x[0] === '?');
    const hash = queryParts.find(x => x[0] === '#');

    const linkQueryString = qs.parse((query || '').replace('?', ''));
    const pageQueryString = qs.parse((search || '').replace('?', ''));
    const builtQueryString = qs.stringify({ ...pageQueryString, ...linkQueryString }) as string;

    // path, querystring, hash
    return [path, builtQueryString ? `?${builtQueryString}` : '', hash || ''];
};

export default useQueryData;
