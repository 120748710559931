import React, { PropsWithChildren } from 'react';
import TabletMockBlack from './tablet-vertical-black.png';

const TabletVertical = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => (
    <div
        className={className}
        css={{
            position: 'relative',
            borderRadius: '2.5vw',
            '&:after': {
                display: 'block',
                width: '100%',
                content: '""',
                paddingBottom: 'calc(2123 / 1628 * 100%)',
            },

            '&:before': {
                top: '0',
                backgroundImage: `url(${TabletMockBlack})`,
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                content: '""',
                display: 'block',
                height: '100%',
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
            },
        }}
    >
        <div
            css={{
                position: 'absolute',
                width: '90.8%',
                left: '4.6%',
                height: '92.8%',
                top: '3.4%',
                borderRadius: 'min(max(1.4vw, 12px), 20px)',
                transform: 'translateZ(0)',
            }}
        >
            {children}
        </div>
    </div>
);

export default TabletVertical;
