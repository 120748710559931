import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import { breakpoints } from '../../../primitives/tokens';
import MediaItem from '../../../primitives/media-item';
import TabletHorizontal from '../../../primitives/devices/tablet-horizontal';

const TabletHorizontalHero = (props: HeroProps): JSX.Element => {
    const { visual } = props;
    return (
        <Background
            css={{
                zIndex: 1,
                position: 'relative',
            }}
            breakpoints={{
                dt: {
                    clip: 'bottom',
                    gradient: 'greyAlphaRadial',
                },
            }}
        >
            <Container>
                <Row
                    css={{
                        gridAutoFlow: 'column',
                    }}
                >
                    <Col breakpoints={{ dt: { span: 5, start: 7 }, mb: { start: 0, span: 4 } }}>
                        <div css={{
                            padding: '176px 0',
                            [breakpoints.tb]: {
                                padding: '126px 0',
                            },
                            [breakpoints.mb]: {
                                paddingTop: '76px',
                                paddingBottom: '0',
                            },
                        }}
                        >
                            <Content withMaxWidth={false} {...props} />
                        </div>
                    </Col>
                    <Col
                        breakpoints={{ dt: { span: 6, start: 0 }, mb: { start: 0, span: 4 } }}
                        css={{
                            alignItems: 'center',
                        }}
                    >
                        <div css={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '100%',
                        }}
                        >
                            {(visual && visual[0] && visual[0].mediaItem) && (
                                <div css={{
                                    padding: '80px 0',
                                    [breakpoints.mb]: {
                                        padding: '40px 0',
                                        maxWidth: 'max(80vw, 450px)',
                                        width: '100%',
                                        margin: 'auto',
                                    },
                                }}
                                >
                                    <TabletHorizontal>
                                        <MediaItem
                                            css={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            item={visual[0].mediaItem}
                                            objectFit="contain"
                                            autoPlay
                                            muted
                                            objectPosition="center center"
                                        />
                                    </TabletHorizontal>
                                </div>
                            )}
                        </div>
                    </Col>

                </Row>
            </Container>
        </Background>
    );
};
export default TabletHorizontalHero;
