import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import TabletVertical from '../../../primitives/devices/tablet-vertical';
import { breakpoints } from '../../../primitives/tokens';
import MediaItem from '../../../primitives/media-item';
import {GetStartedIndividualForm} from "../get-started-form";

const GetStartedFormHero = (props: HeroProps): JSX.Element => {
    return (
        <Background
            css={{
                zIndex: 1,
                position: 'relative',
            }}
            breakpoints={{
                dt: {
                    clip: 'bottom',
                    gradient: 'greyAlphaRadial',
                },
            }}
        >
            <Container>
                <Row
                    css={{
                        gridAutoFlow: 'column',
                    }}
                >
                    <Col breakpoints={{ dt: { span: 6, start: 0 }, mb: { start: 0, span: 4 } }}>
                        <div css={{
                            padding: '176px 0',
                            [breakpoints.tb]: {
                                padding: '126px 0',
                            },
                            [breakpoints.mb]: {
                                paddingTop: '76px',
                                paddingBottom: '0',
                            },
                        }}
                        >
                            <Content withMaxWidth={false} {...props} />
                        </div>
                    </Col>
                    <Col
                        breakpoints={{ dt: { span: 6, start: 6 }, mb: { start: 0, span: 4 } }}
                        css={{
                            alignItems: 'center',
                        }}
                    >
                        <div css={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '100%',
                        }}
                        >
                            <GetStartedIndividualForm
                                page1Title={null}
                                page1Copy={null}
                                page2Title={null}
                                page2Copy={null}
                                successPageTitle={props.successPageTitle}
                                successPageCopy={props.successPageCopy}
                                successPageLink={props.successPageLink}
                            />
                        </div>
                    </Col>

                </Row>
            </Container>
        </Background>
    );
};
export default GetStartedFormHero;
