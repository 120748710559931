import React, { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { PageContext } from 'gatsby/internal';
import useDatoRouter from './use-dato-router';

type LanguageMenuItem = { langKey: string, selected: boolean, datoLocale: string, link: string, isRealPath?: boolean, name: string };

interface LanguageContext {
    language: string
    languageMenu: LanguageMenuItem[]
    country: string|null
}

const LanguageContext = createContext<LanguageContext>({} as never);

interface LanguageProvider {
    location: Location
    children: React.ReactNode
    pageContext: PageContext
    data: any
}

const getInitialFromPath = (pathname): string => {
    if (pathname.startsWith('/au')) return 'au';
    if (pathname.startsWith('/uk')) return 'uk';
    if (pathname.startsWith('/us')) return 'us';
    return 'au';
};

const LanguageProvider = ({ location, children, pageContext, data }: LanguageProvider): JSX.Element => {
    const getRoute = useDatoRouter();
    const {
        site,
    } = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    languages {
                        defaultLangKey
                        langs {
                            langKey
                            datoLocale
                            name
                        }
                    }
                }
            }
        }
    `);

    const [country, setCountry] = React.useState<string|null>(null);
    React.useEffect(() => {
        (async () => {
            const geoLocRaw = await fetch('/app-country');
            const geoLoc = await geoLocRaw.json();
            const countryKey = geoLoc?.country?.name;
            setCountry(countryKey);
        })();
    }, []);

    const initialLanguage = pageContext.lang || getInitialFromPath(location.pathname);

    /*
    * Save the entry point language to state, which can be updated later by the user manually.
    * This will allow us to keep the language switcher showing their preferred language, even if
    * they access untranslated pages, and they can manually switch/bounce back to their preferred
    * language when visiting other (translated) pages.
    * */
    const language = initialLanguage;
    const { langs } = site.siteMetadata.languages;
    const languageMenu: LanguageMenuItem[] = langs.map((item: { langKey: any; datoLocale: any; name: any; }) => {
        const foundRoute = getRoute(
            location.pathname.replace(`/${initialLanguage as string}`, ''),
            item.langKey,
            true,
        );
        return {
            langKey: item.langKey,
            datoLocale: item.datoLocale,
            selected: language === item.langKey,
            link: foundRoute || `/${item.langKey}`,
            isRealPath: !!foundRoute,
            name: item.name,
        };
    });

    const value = useMemo(() => ({
        languageMenu,
        language,
        country,
    }), [language, languageMenu, country]);

    return (
        <LanguageContext.Provider value={value}>
            <Helmet>
                <link rel="canonical" href={`https://uptickhq.com${location.pathname}`} />
                {languageMenu.filter(x => x.isRealPath).map(alternate => (
                    <link
                        rel="alternate"
                        hrefLang={alternate.datoLocale}
                        href={`https://uptickhq.com${alternate.link}/`}
                    />
                ))}
                {languageMenu.filter(x => x.isRealPath && x.langKey === 'au').map(alternate => (
                    <link
                        rel="alternate"
                        hrefLang="x-default"
                        href={`https://uptickhq.com${alternate.link}/`}
                    />
                ))}
            </Helmet>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;

export const useLanguage = (): LanguageContext => {
    const ctx = useContext(LanguageContext);
    if (!ctx) {
        throw new Error('useLanguage is missing parent LanguageProvider.');
    }
    return ctx;
};
