import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Background from '../../../primitives/backgrounds';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import { StyledButtonAsLink } from '../../../primitives/styled-button';
import { getLink } from '../../../primitives/link';
import { colors } from '../../../primitives/colors';
import Typography from '../../../primitives/typography';

const InlineCta = ({ title, text, ctaButton }): JSX.Element => (
    <Container css={{
        margin: '60px auto',
    }}
    >
        <Background
            breakpoints={{
                dt: {
                    backgroundColor: 'grey100'
                },
            }}
            css={{
                borderRadius: borderRadius.large,
                padding: '45px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center',
                gap: '32px',
                '&:after': {
                    content: "''",
                    border: '1px solid #fff2',
                    width: '44vw',
                    height: '44vw',
                    borderRadius: '44vw',
                    position: 'absolute',
                    left: '70px',
                    top: '0',
                    transform: 'translateY(-30%)',
                    [breakpoints.mb]: {
                        width: '70vw',
                        height: '70vw',
                        borderRadius: '70vw',
                        left: '12vw',
                    },
                },
                [breakpoints.mb]: {
                    gap: '16px',
                    padding: '34px',
                },
            }}
        >
            <div>
                <Typography
                    color="primaryDarkPurple"
                    fontSize={{
                        dt: 4053,
                        tb: 3234,
                        mb: 2429,
                    }}
                    css={{
                        paddingBottom: '12px',
                    }}
                    fontWeight="medium"
                >
                    {title}
                </Typography>
                <Typography
                    color="grey200"
                    fontSize={{
                        dt: 1822,
                        tb: 1627,
                        mb: 1420,
                    }}
                    css={{
                        opacity: 0.8,
                    }}
                >
                    {text}
                </Typography>
            </div>
            <StyledButtonAsLink
                css={{
                    color: `${colors.shadesWhite}!important`,
                    border: 'none!important',
                    [breakpoints.mb]: {
                        width: 'unset',
                    },
                }}
                variant="primary"
                to={getLink(ctaButton[0].link)}
            >
                {ctaButton[0].title}
            </StyledButtonAsLink>
        </Background>
    </Container>
);

export default InlineCta;

export const query = graphql`
    fragment InlineCtaProps on DatoCmsInlineCta {
        originalId
        title
        text
        ctaButton {
            ...LinkWithTitleProps
        }
    }
`;
