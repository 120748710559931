import React from 'react';
import clsx from 'clsx';
import Color from '../colors';
import type { ColorProps } from '../colors';
import { PolymorphicWithoutRef } from '../polymorphic';
import { breakpoints } from '../tokens';

const fontSizes = {
    1019: '1019',
    1120: '1120',
    1226: '1226',
    1322: '1322',
    1420: '1420',
    1526: '1526',
    1627: '1627',
    1822: '1822',
    2027: '2027',
    2429: '2429',
    2835: '2835',
    3234: '3234',
    4053: '4053',
    4453: '4453',
    5660: '5660',
    6368: '6368',
};

export const smallTextStyles = {
    textTransform: 'uppercase',
    letterSpacing: '0.72px',
    fontSize: '9px',
    lineHeight: '12px',
    [breakpoints.mb]: {
        fontSize: '11px',
        letterSpacing: '0.88px',
    },
};

export const fontSizeClasses = Object.values(fontSizes).reduce((acc, val) => ({
    ...acc,
    [`dt${val}`]: `dt-${val}`,
    [`tb${val}`]: `tb-${val}`,
    [`mb${val}`]: `mb-${val}`,
}), {});

const fontWeights = {
    light: 'light',
    regular: 'regular',
    bold: 'bold',
    medium: 'medium',
};

const TypographyContext = React.createContext({ override: false });

export const useTypographyOverride = (): boolean => {
    const ctx = React.useContext(TypographyContext);
    return ctx && ctx.override;
};

const Typography = <C extends React.ElementType = 'div'>({
    override,
    fontWeight,
    fontSize,
    className,
    as,
    ...props
}: PolymorphicWithoutRef<C, {
    override?: boolean,
    fontSize?: keyof typeof fontSizes | { [key: string]: keyof typeof fontSizes },
    fontWeight?: keyof typeof fontWeights
} & ColorProps>): JSX.Element => {
    const value = React.useMemo(() => ({ override: override || false }), [override]);
    return (
        <TypographyContext.Provider value={value}>
            <Color
                as={as as React.ElementType}
                {...props}
                className={clsx(
                    className,
                    // Font size key could be string or number depending on typing of Font Size object
                    (fontSize && (typeof fontSize === 'number' || typeof fontSize === 'string')) && `dt-${fontSize}`,
                    ...(fontSize && typeof fontSize === 'object' ? Object.entries(fontSize)
                        .map(([breakpoint, selection]) => `${breakpoint}-${selection}`) : []),
                    fontWeight && fontWeights[fontWeight],
                )}
            />
        </TypographyContext.Provider>
    );
};

export default Typography;
