exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-staff-email-signature-tsx": () => import("./../../../src/pages/staff/email-signature.tsx" /* webpackChunkName: "component---src-pages-staff-email-signature-tsx" */),
  "component---src-pages-uk-staff-email-signature-tsx": () => import("./../../../src/pages/uk/staff/email-signature.tsx" /* webpackChunkName: "component---src-pages-uk-staff-email-signature-tsx" */),
  "component---src-templates-article-tag-tsx": () => import("./../../../src/templates/article-tag.tsx" /* webpackChunkName: "component---src-templates-article-tag-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-comparison-tsx": () => import("./../../../src/templates/comparison.tsx" /* webpackChunkName: "component---src-templates-comparison-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-policy-tsx": () => import("./../../../src/templates/policy.tsx" /* webpackChunkName: "component---src-templates-policy-tsx" */)
}

