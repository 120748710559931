import React from 'react';
import PrimitiveDivider from '../../../primitives/divider';
import Container from '../../../primitives/grid/container';
import { breakpoints } from '../../../primitives/tokens';

const Divider = (): JSX.Element => (
    <Container css={{
        margin: '80px auto',
        [breakpoints.tb]: {
            margin: '60px auto',
        },
        [breakpoints.mb]: {
            margin: '40px auto',
        },
    }}
    >
        <PrimitiveDivider />
    </Container>
);

export default Divider;
