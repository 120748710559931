import React from 'react';
import { useGridContext } from '../grid/context';

interface AspectRatio extends HtmlPropsNoRef<HTMLDivElement> {
    breakpoints: {
        [key: string]: { width: number, height: number } | 'none'
    }
}

const AspectRatio = ({ className, breakpoints, ...props }: AspectRatio): JSX.Element => {
    const { breakpoints: outerBreakpoints } = useGridContext();

    return (
        <div
            className={className}
            css={{
                position: 'relative',
                '&:before': {
                    display: 'block',
                    width: '100%',
                    content: '""',
                    ...Object.fromEntries(Object.entries(breakpoints).map(([selected, ratio]) => {
                        const { query } = outerBreakpoints[selected];
                        if (ratio === 'none') {
                            return [
                                query, {
                                    paddingBottom: '0',
                                },
                            ];
                        }
                        const { width, height } = ratio;
                        return [query, {
                            paddingBottom: `calc(${height} / ${width} * 100%)`,
                        }];
                    })),
                },
            }}
        >
            <div
                css={{
                    width: '100%',
                    ...Object.fromEntries(Object.entries(breakpoints).map(([selected, ratio]) => {
                        const { query } = outerBreakpoints[selected];
                        if (ratio === 'none') {
                            return [
                                query, {
                                    height: '100%',
                                    position: 'relative',
                                },
                            ];
                        }
                        return [query, {
                            position: 'absolute',
                            left: '0',
                            height: '100%',
                            top: '0',
                        }];
                    })),
                }}
                {...props}
            />
        </div>
    );
};

export default AspectRatio;
