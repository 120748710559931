import React, { PropsWithChildren } from 'react';
import MobileMockBlack from './mobile-black.png';

const Mobile = ({ children, className, largeFormat }: PropsWithChildren<{ className?: string, largeFormat?: boolean }>): JSX.Element => (
    <div
        className={className}
        css={{
            position: 'relative',
            '&:after': {
                display: 'block',
                width: '100%',
                content: '""',
                paddingBottom: 'calc(1776 / 884 * 100%)',
            },

            '&:before': {
                top: '0',
                backgroundImage: `url(${MobileMockBlack})`,
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                content: '""',
                display: 'block',
                height: '100%',
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
            },
        }}
    >
        <div css={{
            position: 'absolute',
            width: '89%',
            left: '5.5%',
            height: '96%',
            top: '2%',
            borderRadius: largeFormat ? 'min(max(1.9vw, 24px), 26px)' : 'min(max(1.4vw, 12px), 20px)',
            transform: 'translateZ(0)',
            overflow: 'hidden',
        }}
        >
            {children}
        </div>
    </div>
);

export default Mobile;
