import React from 'react';
import { colors } from '../colors';

/**
 * Simple Accessible Alert component that can be re-used.
 * @alias HtmlPropsHTMLSpanElement>
 * */
const FormAlert = React.forwardRef<
HTMLSpanElement,
HtmlProps<HTMLSpanElement>
>((props, ref) => (
    <span
        ref={ref}
        role="alert"
        css={{
            display: 'block',
            color: colors.statesError,
            paddingTop: '6px',
            fontSize: '12px',
            fontWeight: '600',
        }}
        {...props}
    />
));

FormAlert.displayName = 'FormAlert';

export default FormAlert;
