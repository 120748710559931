import React from 'react';
import { graphql } from 'gatsby';
// eslint-disable-next-line import/no-cycle
import CustomerSuccessGrabCta, { CustomerSuccessGrabCtaProps } from '../customer-success-grab-cta';

export type FeaturedCaseStudyProps = Queries.FeaturedCaseStudyPropsFragment;

const FeaturedCaseStudy = (props: FeaturedCaseStudyProps): JSX.Element => {
    const caseStudy = props?.caseStudy?.caseStudyFeature?.[0] as CustomerSuccessGrabCtaProps;
    return (
        <CustomerSuccessGrabCta {...caseStudy} />
    );
};

export const query = graphql`
    fragment FeaturedCaseStudyProps on DatoCmsFeaturedCaseStudy {
        caseStudy {
            caseStudyFeature {
                ...CustomerSuccessGrabCtaProps
            }
        }
    }
`;

export default FeaturedCaseStudy;
