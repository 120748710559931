import React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import Link from '../link';
import { colors } from '../colors';
import Typography from '../typography';
import { breakpoints } from '../tokens';

const StyledLink = ({ className, isDynamic = false, ...props }: GatsbyLinkProps<never> & { isDynamic?: boolean }): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Typography
        as={Link}
        fontSize={{
            dt: 1322,
        }}
        className={className}
        css={{
            position: 'relative',
            transition: '0.3s',
            color: colors.primaryPurple,
            '[role=group]:hover &': {
                '&::before': {
                    transform: 'scale(1)',
                    right: '0',
                    transformOrigin: 'bottom left',
                },
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '0',
                height: '1px',
                bottom: '-2px',
                width: '100%',
                backgroundColor: colors.primaryLightPurple,
                transition: '0.3s transform ease-out',
                transform: isDynamic ? 'scale(0)' : 'scale(1)',
                transformOrigin: 'bottom right',
                [breakpoints.mb]: isDynamic ? {} : {
                    transformOrigin: 'bottom left',
                    transform: 'scale(1)',
                    opacity: 0.5,
                    right: '0',
                },
            },
            '&:hover::before': {
                transform: 'scale(1)',
                right: '0',
                transformOrigin: 'bottom left',
            },
        }}
        {...props}
    />
);

export default StyledLink;
