import React from 'react';
import { useGridContext } from './context';

const strengths = {
    full: [5, 'vw'],
    half: [2.5, 'vw'],
    none: [0, 'vw'],
};

interface Container extends HtmlPropsNoRef<HTMLDivElement> {
    breakpoints?: {
        [key: string]: { strength: keyof typeof strengths, vertical?: boolean }
    },
    canStack?: boolean
}

const ContainerContext = React.createContext<boolean>(false);

const Container = ({ breakpoints, canStack, ...props }: Container): JSX.Element => {
    const { breakpoints: outerBreakpoints, maxWidth } = useGridContext();
    const inContainer = React.useContext(ContainerContext);

    const breakPointsToUse = breakpoints || { dt: { strength: 'full' }, mb: { strength: 'full' } };
    const shouldBuildStyles = (!inContainer || (inContainer && canStack));

    return (
        <ContainerContext.Provider value>
            <div
                css={{
                    margin: 'auto',
                    maxWidth: `calc(${maxWidth}px + 10vw)`,
                    ...(shouldBuildStyles ? Object.fromEntries(
                        Object.entries(breakPointsToUse).map(([selected, { strength, vertical }]) => {
                            const { query } = outerBreakpoints[selected];
                            const [number, units] = strengths[strength];

                            return [query, vertical ? {
                                padding: `${number}${units}`,
                            } : {
                                paddingLeft: `${number}${units}`,
                                paddingRight: `${number}${units}`,
                            }];
                        }),
                    ) : {}),
                }}
                {...props}
            />
        </ContainerContext.Provider>
    );
};

export default Container;
