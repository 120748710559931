import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import { breakpoints } from '../../../primitives/tokens';
import type { MediaItemAsset } from '../../../primitives/media-item';
import ArticleCard from '../articles-index/article-card';
import { useLanguage } from '../../../language/language-provider';
import DatoAsset from '../../../primitives/asset';

export type CaseStudyIndexProps = Queries.CaseStudyIndexPropsFragment;

const CaseStudyIndex = (): JSX.Element => {
    const { language } = useLanguage();
    const staticData = useStaticQuery<Queries.AllCaseStudyQuery>(graphql`
        query AllCaseStudy {
            au: allDatoCmsCaseStudy(locale: "en-AU", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    clientLogo {
                        ...DatoCmsInternalAsset
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
            uk: allDatoCmsCaseStudy(locale: "en-GB", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    clientLogo {
                        ...DatoCmsInternalAsset
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
            us: allDatoCmsCaseStudy(locale: "en-US", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    clientLogo {
                        ...DatoCmsInternalAsset
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
        }
    `);

    const {
        nodes: allArticles,
    } = staticData[language as 'au' | 'uk' | 'us'];

    return (
        <Container css={{
            marginTop: '82px',
            marginBottom: '60px',
        }}
        >
            <Row css={{
                rowGap: '24px',
                [breakpoints.mb]: {
                    rowGap: '16px',
                },
            }}
            >
                {allArticles.sort((a, b) => a.position - b.position).map((article) => {
                    if (article.hideForLocale) {
                        return null;
                    }

                    return (
                        <Col breakpoints={{ dt: { span: 4 } }}>
                            <ArticleCard
                                id={article.id}
                                title={article.title}
                                excerpt={article.excerpt}
                                permalink={article.permalink}
                                primaryMedia={article.primaryMedia as MediaItemAsset}
                                thumbnail={article.thumbnail}
                                tag={(
                                    <div css={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                        <DatoAsset
                                            css={{
                                                maxHeight: '20px',
                                                objectFit: 'contain',
                                            }}
                                            objectFit="contain"
                                            objectPosition="left"
                                            asset={article?.clientLogo}
                                        />
                                    </div>
                                )}
                            />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

export default CaseStudyIndex;
