import React, { PropsWithChildren } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export type DirectionTransitionDirection = 'forward'|'backward';

const directionVariants = {
    enter: (direction: DirectionTransitionDirection) => ({
        opacity: 0,
        x: direction === 'forward' ? 10 : -10,
    }),
    idle: () => ({
        opacity: 1,
        x: 0,
        transition: { duration: 0.6 },
    }),
    exit: (direction: DirectionTransitionDirection) => ({
        opacity: 0,
        x: direction === 'forward' ? -20 : 20,
        transition: { duration: 0.2 },
    }),
};

const DirectionTransition = ({
    direction,
    shouldChange,
    ...props
}: PropsWithChildren<{
    direction: DirectionTransitionDirection,
    shouldChange: string,
    className?: string,
    style?: React.CSSProperties
}>): JSX.Element => (
    <AnimatePresence
        exitBeforeEnter
        custom={direction}
    >
        <motion.div
            key={shouldChange}
            variants={directionVariants}
            custom={direction}
            exit="exit"
            animate="idle"
            initial="enter"
            {...props}
        />
    </AnimatePresence>
);

export default DirectionTransition;
