/* eslint-disable @typescript-eslint/naming-convention */
import './reset.css';
import '@reach/skip-nav/styles.css';
import './theme.scss';
import './fonts/stylesheet.css';
import React from 'react';
import { PageProps } from 'gatsby';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { Helmet } from 'react-helmet';
import CenterLineDebug from '../components/debug/center-line-debug';
import DebugProvider from '../components/debug/debug-provider';
import GridLinesDebug from '../components/debug/grid-lines-debug';
import PageDebug from '../components/debug/page-debug';
import FadeTransition from '../components/animations/transitions/fade-transition';
import LanguageProvider from '../components/language/language-provider';
import GridContextProvider from '../components/primitives/grid/context';
import { breakpoints } from '../components/primitives/tokens';
import Footer from '../components/footer';
import Header from '../components/header';
import GlobalSeo from '../components/global-seo';
import { trackPageView } from '../util/analytics';
import { colors } from '../components/primitives/colors';

const Layout = (props: PageProps): JSX.Element => {
    const { children, location, pageContext } = props;

    React.useEffect(() => {
        trackPageView();
    }, [location.pathname]);

    return (
        <GridContextProvider
            breakpoints={{
                dt: { query: breakpoints.dt, columns: 12, gap: 42 },
                tb: { query: breakpoints.tb, columns: 12, gap: 24 },
                mb: { query: breakpoints.mb, columns: 4, gap: 16 },
            }}
            maxWidth={1440}
            desktopFirst
        >
            <Helmet
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                htmlAttributes={typeof window === 'undefined' ? {
                    style: {
                        '--header-color-1': colors.primaryDarkPurple,
                        '--header-color-2': colors.primaryOrange,
                        '--header-color-3': colors.primaryOrange,
                        '--header-color-4': colors.shadesWhite,
                        '--header-color-5': colors.primaryLightOrangeHover,
                        '--header-color-6': colors.primaryPurple,
                        '--header-color-7': colors.grey100,
                    },
                } : {
                    // eslint-disable-next-line max-len
                    style: `--header-color-1: ${colors.primaryDarkPurple}; --header-color-2:${colors.primaryOrange}; --header-color-3:${colors.primaryOrange}; --header-color-4:${colors.shadesWhite}; --header-color-5: ${colors.primaryLightOrangeHover}; --header-color-6: ${colors.primaryPurple}; --header-color-7: ${colors.grey100}`,
                }}
            />
            <TooltipProvider>
                <DebugProvider id={props?.pageContext?.originalId}>
                    <PageDebug {...props} />
                    <GridLinesDebug />
                    <CenterLineDebug />
                    <LanguageProvider location={location} pageContext={pageContext}>
                        <GlobalSeo />
                        {location.pathname.includes('powered-by-uptick/sign-in') ? (
                            <div css={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                                {children}
                            </div>
                        ) : (
                            <>
                                <SkipNavLink
                                    contentId="skip-nav"
                                    css={{
                                        marginTop: '32px',
                                        padding: '20px',
                                    }}
                                />
                                <Header />
                                <div css={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                                    <FadeTransition shouldChange={location.pathname}>
                                        <SkipNavContent css={{ width: '100%' }} id="skip-nav">
                                            {children}
                                            <Footer />
                                        </SkipNavContent>
                                    </FadeTransition>
                                </div>
                            </>
                        )}
                    </LanguageProvider>
                </DebugProvider>
            </TooltipProvider>
        </GridContextProvider>
    );
};

export default Layout;
