import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import MediaItemWithDecoration from '../../../primitives/media-item-with-decoration';
import { breakpoints } from '../../../primitives/tokens';
import { colors } from '../../../primitives/colors';

const DecoratedMediaItemHero = (props: HeroProps): JSX.Element => {
    const { visual } = props;
    return (
        <Background
            css={{
                zIndex: 1,
                backgroundColor: colors.shadesWhite,
                position: 'relative',
            }}
            breakpoints={{
                dt: {
                    gradient: 'greyAlphaRadial',
                    clip: 'bottom',
                },
                mb: {
                    gradient: 'none',
                    clip: 'bottom',
                },
            }}
        >
            <Container>
                <Row css={{
                    gridAutoFlow: 'column',
                }}
                >
                    <Col breakpoints={{ dt: { span: 6, start: 6 }, mb: { span: 4, start: 0 } }}>
                        <div css={{
                            padding: '176px 0',
                            [breakpoints.tb]: {
                                padding: '126px 0',
                            },
                            [breakpoints.mb]: {
                                paddingTop: '76px',
                                paddingBottom: '38px',
                            },
                        }}
                        >
                            <Content withMaxWidth={false} {...props} />
                        </div>
                    </Col>
                    <Col breakpoints={{ dt: { span: 6, start: 0 }, tb: { span: 5, start: 0 }, mb: { span: 4, start: 0 } }}>
                        {(visual && visual[0] && visual[0].mediaItem) && (
                            <div css={{
                                margin: 'auto 0',
                                paddingLeft: '10%',
                                [breakpoints.tb]: {
                                    marginLeft: '0%',
                                    paddingLeft: '0',
                                    width: '118%',
                                },
                                [breakpoints.mb]: {
                                    marginLeft: '0',
                                    width: '100%',
                                    paddingLeft: '12%',
                                },
                            }}
                            >
                                <MediaItemWithDecoration
                                    css={{
                                        height: '100%',
                                    }}
                                    objectFit="cover"
                                    autoPlay
                                    muted
                                    objectPosition="center center"
                                    orientation="decoration-on-left-right-on-mobile"
                                    item={visual[0].mediaItem}
                                    noAnimate
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </Background>
    );
};
export default DecoratedMediaItemHero;
