/* eslint-disable import/no-extraneous-dependencies,@typescript-eslint/ban-ts-comment */
// @ts-ignore
import { useLocation } from '@gatsbyjs/reach-router';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
// @ts-ignore
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useLanguage } from './language/language-provider';

const GlobalSeo = (): JSX.Element => {
    const { pathname } = useLocation();
    const { language, languageMenu } = useLanguage();

    const data = useStaticQuery<Queries.GlobalSeoQuery>(
        graphql`
          query GlobalSeo {
              site {
                  siteMetadata {
                      siteUrl
                  }  
              }
              datoCmsSite {
                  faviconMetaTags {
                      ...GatsbyDatoCmsFaviconMetaTags
                  }
                  globalSeo {
                      siteName
                      titleSuffix
                      twitterAccount
                      facebookPageUrl
                      fallbackSeo {
                          title
                          description
                          image {
                              url
                          }
                          twitterCard
                      }
                  }
              }
          }
        `,
    );

    const { site } = data;
    const { titleSuffix, twitterAccount } = data.datoCmsSite?.globalSeo || {};
    const { title, description, image, twitterCard } = data.datoCmsSite?.globalSeo?.fallbackSeo || {};
    const foundWholeLanguage = languageMenu.find(x => x.langKey === language);

    return (
        <HelmetDatoCms htmlAttributes={{ lang: foundWholeLanguage.datoLocale }} favicon={data.datoCmsSite?.faviconMetaTags}>
            <title>
                {title}
                {titleSuffix}
            </title>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            {description && <meta name="description" content={description} />}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {image?.url && <meta property="og:image" content={image?.url} />}
            <meta property="og:locale" content={foundWholeLanguage.datoLocale.replace('-', '_')} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`${site?.siteMetadata?.siteUrl as string|undefined || 'https://uptickhq.com'}${pathname as string}`} />
            {twitterCard && <meta name="twitter:card" content={twitterCard} />}
            {twitterAccount && <meta name="twitter:creator" content={twitterAccount} />}
            {title && <meta name="twitter:title" content={title} />}
            {description && <meta name="twitter:description" content={description} />}
            {image?.url && <meta name="twitter:image" content={image?.url} />}
        </HelmetDatoCms>
    );
};

export default GlobalSeo;
