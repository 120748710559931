import React from 'react';

const DebugPill = (props: HtmlPropsNoRef<HTMLDivElement>): JSX.Element => (
    <div
        css={{
            backgroundColor: 'green',
            borderRadius: '1000px',
            padding: '4px 12px',
            margin: '8px',
            fontSize: '12px',
            color: 'white',
            boxShadow: '0 0 4px #0003',
            display: 'inline-block',
            zIndex: '100',
        }}
        {...props}
    />
);

export default DebugPill;
