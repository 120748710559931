import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import { breakpoints } from '../../../primitives/tokens';
import { colors } from '../../../primitives/colors';

const NoVisualHero = (props: HeroProps): JSX.Element => (
    <Background
        css={{
            zIndex: 1,
            position: 'relative',
            backgroundColor: colors.shadesWhite,
            [breakpoints.mb]: {
                marginTop: '65px',
                paddingBottom: '45px',
            },
        }}
        breakpoints={{
            dt: {
                clip: 'bottom',
                gradient: 'greyAlphaRotated',
            },
            mb: {
                clip: 'bottom',
                gradient: 'greyAlphaRotated',
            },
        }}
    >
        <Container>
            <Row>
                <Col breakpoints={{ dt: { span: 12 } }}>
                    <div css={{
                        paddingTop: '85px',
                        paddingBottom: '70px',
                        margin: 'auto',
                        [breakpoints.mb]: {
                            paddingTop: '0',
                            paddingBottom: '0',
                        },
                    }}
                    >
                        <Content centered {...props} />
                    </div>
                </Col>
            </Row>
        </Container>
    </Background>
);
export default NoVisualHero;
