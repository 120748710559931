const universalClickEvent = (text: string, data?: { [key: string]: string | number }): void => {
    if (typeof window !== 'undefined') {
        if (process.env.NODE_ENV === 'development') {
            // alert(`Event Triggered: ${text}`);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({
            event: text,
            ...data,
        });
    }
};

const trackPageView = (overrideUrl?: string): void => {
    if (typeof window !== 'undefined') {
        const pageCategory = window.location.href.split('/')[2];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({
            event: 'virtualPageView',
            url: overrideUrl || window.location.href,
            title: document.title,
            category: pageCategory || 'Homepage',
        });
    }
};

const trackDemoCtaClick = (location: 'header' | 'footer' | 'on_page'): void => {
    if (typeof window !== 'undefined') {
        universalClickEvent('click_on_demo_CTA', { location, url: window.location.href });
    }
};

const trackMainCtaHeaderClick = (): void => {
    universalClickEvent('main-cta-header-click');
};

const trackMainCtaHeroClick = (): void => {
    universalClickEvent('main-cta-hero-click');
};

const trackMainCtaFooterClick = (): void => {
    universalClickEvent('main-cta-bottom-click');
};

const trackMainCtaOnPageClick = (): void => {
    universalClickEvent('main-cta-onpage-click');
};

const trackMainCtaVideoClick = (): void => {
    universalClickEvent('main-cta-video-click');
};

const trackSecondaryCtaHeroClick = (): void => {
    universalClickEvent('secondary-cta-hero-click');
};

const trackFormStep1View = (): void => {
    universalClickEvent('lead-form-step1');
};

const trackFormStep1UserInput = (): void => {
    universalClickEvent('lead-form-step1-started');
};

const trackFormStep1Completed = (): void => {
    universalClickEvent('lead-form-step1-completed');
};

const trackFormStep2View = (): void => {
    universalClickEvent('lead-form-step2');
};

const trackFormStep2UserInput = (): void => {
    universalClickEvent('lead-form-step2-started');
};

const trackFormStep2Completed = (): void => {
    universalClickEvent('lead-form-step2-completed');
};

const trackFormStep3CtaClicked = (): void => {
    universalClickEvent('lead-form-step3-cta-clicked');
};

const trackLanguageSwitch = (lang: string): void => {
    universalClickEvent('langSwitch', { lang });
    universalClickEvent(`langSwitch:${lang}`);
};

const trackDemoVideoPlayed = (): void => {
    universalClickEvent('demo-video-played');
};

export {
    trackPageView,
    trackDemoCtaClick,
    trackLanguageSwitch,
    trackDemoVideoPlayed,

    trackFormStep1View,
    trackFormStep1UserInput,
    trackFormStep1Completed,

    trackFormStep2View,
    trackFormStep2UserInput,
    trackFormStep2Completed,

    trackFormStep3CtaClicked,

    trackMainCtaHeaderClick,
    trackMainCtaHeroClick,
    trackMainCtaVideoClick,
    trackMainCtaFooterClick,
    trackMainCtaOnPageClick,

    trackSecondaryCtaHeroClick,
};
