import React from 'react';
import { graphql } from 'gatsby';
import DatoAsset from '../asset';

export type MediaItemAsset = Queries.MediaItemPropsFragment[];

export interface MediaItemProps {
    item?: MediaItemAsset
    className?: string;
    style?: React.CSSProperties;
    objectFit?: React.CSSProperties['objectFit'];
    objectPosition?: React.CSSProperties['objectPosition'];
    autoPlay?: boolean;
    muted?: boolean;
    isStatic?: boolean;
    onPlayStateChange?: (state: 'play' | 'pause') => void
    noAnimate?: boolean
    cannotOverrideInline?: boolean
    isBlog?: boolean
}

const MediaItem = ({
    item, className, noAnimate, style, objectFit, objectPosition, autoPlay, muted, isStatic, onPlayStateChange, isBlog, cannotOverrideInline,
}: MediaItemProps): JSX.Element | null => {
    const media = item?.[0]?.media?.[0] || null;

    if (!media) return null;

    return (
        <DatoAsset
            noAnimate={noAnimate}
            objectFit={objectFit}
            objectPosition={objectPosition}
            className={className}
            style={style}
            asset={media.image || media.video || media.externalVideo}
            autoPlay={autoPlay || (media.isInline && !cannotOverrideInline)}
            muted={muted || media.isInline}
            isStatic={isStatic}
            onPlayStateChange={onPlayStateChange}
            isBlog={isBlog}
            cannotOverrideInline={cannotOverrideInline}
        />
    );
};

export default MediaItem;

export const query = graphql`
    fragment MediaItemProps on DatoCmsMediaItem {
        originalId
        media {
            __typename
            ... on DatoCmsImage {
                image {
                    ...DatoCmsInternalAsset
                }
            }
            ... on DatoCmsVideo {
                video {
                    ...DatoCmsInternalAsset
                }
                isInline
            }
            ... on DatoCmsExternalVideo {
                externalVideo {
                    ...DatoCmsExternalAsset
                }
            }
        }
    }
`;
