import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import { breakpoints } from '../../../primitives/tokens';

export type UptickInNumbersSetProps = Queries.UptickInNumbersSetPropsFragment;

const UptickInNumbersSet = ({ richTitle, stats }: UptickInNumbersSetProps): JSX.Element => (
    <Container css={{
        marginTop: '65px',
        marginBottom: '65px',
        [breakpoints.mb]: {
            marginTop: '52px',
            marginBottom: '52px',
        },
    }}
    >
        <Row css={{
            rowGap: '18px',
        }}
        >
            <Col
                breakpoints={{ dt: { span: 3 }, tb: { span: 12 } }}
            >
                <Typography
                    fontSize={{
                        dt: 4053,
                        mb: 3234,
                    }}
                    override
                    fontWeight="light"
                >
                    <CustomStructuredTextRenderer data={richTitle} />
                </Typography>
            </Col>
            <Col
                breakpoints={{ dt: { span: 9 }, tb: { span: 12 } }}
            >
                <div css={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    [breakpoints.mb]: {
                        flexWrap: 'wrap',
                        height: 'unset',
                        rowGap: '30px',
                    },
                }}
                >
                    {(stats || []).map(stat => (
                        <div css={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            height: 'calc(100% - 20px)',
                            [breakpoints.mb]: {
                                height: 'unset',
                                width: '50%',
                            },
                        }}
                        >
                            <Typography
                                fontSize={{
                                    dt: 4453,
                                    tb: 3234,
                                    mb: 2429,
                                }}
                                fontWeight="light"
                            >
                                {stat?.number}
                            </Typography>
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                }}
                            >
                                {stat?.text}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    </Container>
);

export default UptickInNumbersSet;

export const query = graphql`
    fragment UptickInNumbersSetProps on DatoCmsUptickInNumbersSet {
        richTitle {
            blocks
            links
            value
        }
        stats {
            number
            text
        }
    }
`;
