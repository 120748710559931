import React from 'react';
import MediaItem from '../../../primitives/media-item';
import { borderRadius, breakpoints } from '../../../primitives/tokens';

const MediaItemAsBlock = ({ media, isBlog }): JSX.Element => {
    if (isBlog) {
        return (
            <div css={{
                width: '100%',
                margin: '100px 0',
                overflow: 'hidden',
                transform: 'translateZ(0)',
                borderRadius: borderRadius.extraLarge,
                [breakpoints.tb]: {
                    margin: '75px 0',
                },
                [breakpoints.mb]: {
                    margin: '50px 0',
                    borderRadius: borderRadius.large,
                },
            }}
            >
                <MediaItem isBlog={isBlog} css={{ margin: 'auto', height: '100%', width: '100%', objectFit: 'contain' }} objectFit="contain" item={[{ media }]} />
            </div>
        );
    }

    return (
        <MediaItem item={[{ media }]} />
    );
};

export default MediaItemAsBlock;
