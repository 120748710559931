import React, { PropsWithChildren } from 'react';
import TabletMockBlack from './tablet-horizontal-black.png';

const TabletHorizontal = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => (
    <div
        className={className}
        css={{
            position: 'relative',
            borderRadius: '3.5vw',
            '&:after': {
                display: 'block',
                width: '100%',
                content: '""',
                paddingBottom: 'calc(1628 / 2123 * 100%)',
            },

            '&:before': {
                top: '0',
                backgroundImage: `url(${TabletMockBlack})`,
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                content: '""',
                display: 'block',
                height: '100%',
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
            },
        }}
    >
        <div css={{
            position: 'absolute',
            height: '91%',
            top: '4.5%',
            width: '93%',
            left: '3.65%',
            borderRadius: 'min(max(1.4vw, 12px), 20px)',
            transform: 'translateZ(0)',
            // overflow: 'hidden',
        }}
        >
            {children}
        </div>
    </div>
);

export default TabletHorizontal;
