/*
 * Return an array of unique objects based on predetermined identifier key/value comparison (i.e. an ID)
 * Basically like [...new Set()] for objects
 * */

function objectDedupe<Data = object>(
    inputArray: Data[],
    identifier: keyof Data,
): Data[] {
    const result: Data[] = [];
    const map = new Map();

    inputArray.forEach((item) => {
        if (!map.has(item[identifier])) {
            map.set(item[identifier], true); // set any value to Map
            result.push(item);
        }
    });

    return result;
}

export default objectDedupe;
