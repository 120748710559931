import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { colors } from '../colors';
import { borderRadius } from '../tokens';
import { smallTextStyles } from '../typography';

const Badge = ({ invert, color = 'orange', ...props }: HtmlProps<HTMLDivElement> & { invert?: boolean, color?: 'orange' | 'purple' }): JSX.Element => {
    const theme = {
        orange: {
            normal: [colors.primaryLightOrange, colors.primaryOrange],
            invert: [colors.primaryOrange, colors.shadesWhite],
        },
        purple: {
            normal: [colors.primaryLightPurple, colors.primaryPurple],
            invert: [colors.primaryPurple, colors.shadesWhite],
        },
    }[color][invert ? 'invert' : 'normal'];

    return (
        <div
            css={{
                backgroundColor: theme[0],
                color: theme[1],
                padding: '4px 8px',
                borderRadius: borderRadius.small,
                textTransform: 'uppercase',
                letterSpacing: '0.72px',
                fontSize: '9px',
                lineHeight: '12px',
                fontWeight: '600',
                display: 'inline-block',
                transition: '0.3s',
                'a:hover &': {
                    backgroundColor: theme[1],
                    color: theme[0],
                },
            } as Interpolation<Theme>}
            {...props}
        />
    );
};

export default Badge;
