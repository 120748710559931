import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import Button, { ButtonProps, buttonStyles } from '../button';
import { colors } from '../colors';
import { borderRadius, breakpoints } from '../tokens';
import Link, { LinkProps } from '../link';

interface StyledButtonStyleProps {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'darkPrimary' | 'darkSecondary' | 'header'
}

export const styledButtonStyles = ({ variant }: StyledButtonStyleProps): Interpolation<Theme> => ({
    overflow: 'hidden',
    position: 'relative',
    padding: '0 40px',
    height: '48px',
    fontSize: '13px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1!important',
    backgroundColor: (() => {
        if (variant === 'header') {
            return `var(--header-color-4, ${colors.shadesWhite})`;
        }
        if (variant === 'secondary' || variant === 'tertiary') {
            return colors.shadesWhite;
        }
        if (variant === 'darkPrimary') {
            return colors.primaryPurple;
        }
        if (variant === 'darkSecondary') {
            return 'transparent';
        }
        return colors.primaryOrange;
    })(),
    boxShadow: (() => {
        if (variant === 'secondary' || variant === 'tertiary' || variant === 'darkSecondary' || variant === 'header') {
            return 'none';
        }
        if (variant === 'darkPrimary') {
            return '0 7px 14px 0px rgba(0, 0, 0, 0.24)';
        }
        return '0 5px 14px 0px rgba(255, 79, 0, 0.15)';
    })(),
    fontWeight: 500,
    color: (() => {
        if (variant === 'header') {
            return `var(--header-color-2, ${colors.primaryOrange})`;
        }
        if (variant === 'secondary' || variant === 'tertiary') {
            return colors.primaryOrange;
        }
        return colors.shadesWhite;
    })(),
    borderRadius: borderRadius.small,
    border: (() => {
        if (variant === 'header') {
            return `1px solid var(--header-color-3, ${colors.primaryOrange})`;
        }
        if (variant === 'darkPrimary') {
            return `1px solid ${colors.primaryPurple}`;
        }
        if (variant === 'darkSecondary') {
            return '1px solid #fff8';
        }
        return `1px solid ${colors.primaryOrange}`;
    })(),
    svg: (() => {
        if (variant === 'darkSecondary') {
            return {
                path: {
                    color: colors.shadesWhite,
                    fill: colors.shadesWhite,
                },
            };
        }
        return {};
    })(),
    transition: '0.3s',
    '&:hover': {
        backgroundColor: (() => {
            if (variant === 'header') {
                return `var(--header-color-5, ${colors.primaryLightOrangeHover})`;
            }
            if (variant === 'secondary' || variant === 'tertiary') {
                return colors.primaryLightOrangeHover;
            }
            if (variant === 'darkPrimary') {
                return colors.primaryPurpleHover;
            }
            if (variant === 'darkSecondary') {
                return colors.primaryOrange;
            }
            return colors.primaryOrangeHover;
        })(),
    },
    [breakpoints.mb]: variant === 'secondary' ? {
        lineHeight: '1!important',
        fontSize: '13px',
        backgroundColor: 'transparent',
        padding: '0',
        border: 'none',
        borderBottom: `1px solid ${colors.primaryLightOrange}`,
        paddingBottom: '4px',
        height: 'unset',
        width: 'fit-content',
        display: 'inline-block',
        svg: {
            display: 'none',
        },
        margin: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    } : {
        lineHeight: '1!important',
        fontSize: '13px',
        width: '100%',
    },
});

const StyledButton = React.forwardRef<HTMLButtonElement, ButtonProps & StyledButtonStyleProps>(({
    variant,
    ...props
}, ref) => (
    <Button
        ref={ref}
        css={styledButtonStyles({ variant })}
        {...props}
    />
));

export const StyledButtonAsLink = ({ variant, ...props }: NoRef<LinkProps> & StyledButtonStyleProps): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Link
        css={{
            ...buttonStyles as unknown as EmotionJSX.Element,
            ...styledButtonStyles({ variant }) as unknown as EmotionJSX.Element,
            zIndex: '1',
        }}
        {...props}
    />
);

export default StyledButton;
