import React from 'react';
import { PageProps } from 'gatsby';
import { useDebug } from './debug-provider';
import DebugPill from './debug-pill';

const PageDebug = ({ pageResources }: Omit<PageProps, 'children'>): JSX.Element | null => {
    const { showTemplateNames } = useDebug();

    if (showTemplateNames) {
        return (
            <DebugPill>
                Template:
                {' '}
                {pageResources.page.componentChunkName}
            </DebugPill>
        );
    }

    return null;
};

export default PageDebug;
