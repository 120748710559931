import React from 'react';
import { PolymorphicWithoutRef } from '../polymorphic';
import { colors } from '../colors';
import { useGridContext } from '../grid/context';

export const gradients = {
    orange: 'var(--gradient-orange)',
    orangeReverse: 'var(--gradient-orange-reverse)',
    tangerine: 'var(--gradient-tangerine)',
    blackAlpha: 'var(--gradient-black-alpha)',
    greyAlpha: 'var(--gradient-grey-alpha)',
    greyAlphaRotated: 'var(--gradient-grey-alpha-rotated)',
    greyAlphaRadial: 'var(--gradient-grey-alpha-radial)',
    purple: 'var(--gradient-purple)',
    purpleFromTop: 'var(--gradient-purple-from-top)',
};

type GradientProps = {
    breakpoints: {
        [key: string]: {
            backgroundColor?: keyof typeof colors | 'none'
            gradient?: keyof typeof gradients | 'none',
            clip?: 'top' | 'bottom' | 'none',
            clipSize?: number
        }
    }
};

const Background = <C extends React.ElementType = 'div'>({
    as,
    className,
    breakpoints,
    ...props
}: PolymorphicWithoutRef<C, GradientProps>): JSX.Element => {
    const { breakpoints: outerBreakpoints } = useGridContext();
    const Component = as || 'div';
    return (
        <Component
            {...props}
            className={className}
            css={{
                ...Object.fromEntries(Object.entries(breakpoints).map(([selected, {
                    gradient,
                    clip,
                    clipSize = 800,
                    backgroundColor,
                }]) => {
                    const { query } = outerBreakpoints[selected];
                    return [query, {
                        backgroundColor: (() => {
                            if (backgroundColor === 'none') {
                                return 'transparent';
                            }
                            if (backgroundColor && colors[backgroundColor]) {
                                return colors[backgroundColor];
                            }
                            return undefined;
                        })(),
                        // position: 'relative',
                        backgroundImage: (() => {
                            if (gradient === 'none') {
                                return 'none';
                            }
                            if (gradient && gradients[gradient]) {
                                return gradients[gradient];
                            }
                            return undefined;
                        })(),
                        clipPath: (() => {
                            if (clip === 'none') {
                                return 'none';
                            }
                            if (clip === 'top') {
                                return `circle(${clipSize}vw at 50% ${clipSize}vw)`;
                            }
                            if (clip === 'bottom') {
                                return `circle(${clipSize}vw at 50% calc(-${clipSize}vw + 100%))`;
                            }
                            return undefined;
                        })(),
                        paddingTop: (() => {
                            if (clip === 'none') {
                                return 'unset';
                            }
                            if (clip === 'top') {
                                return '30px';
                            }
                            return undefined;
                        })(),
                        marginTop: (() => {
                            if (clip === 'none') {
                                return 'unset';
                            }
                            if (clip === 'top') {
                                return '-30px';
                            }
                            return undefined;
                        })(),
                        paddingBottom: (() => {
                            if (clip === 'none') {
                                return 'unset';
                            }
                            if (clip === 'bottom') {
                                return '30px';
                            }
                            return undefined;
                        })(),
                        marginBottom: (() => {
                            if (clip === 'none') {
                                return 'unset';
                            }
                            if (clip === 'bottom') {
                                return '-30px';
                            }
                            return undefined;
                        })(),
                    }];
                })),
            }}
        />
    );
};
export default Background;
