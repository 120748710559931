import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import SolutionsCard from '../solutions-carousel/card';
import { breakpoints } from '../../../primitives/tokens';

export type BenefitsGridProps = Queries.BenefitsGridPropsFragment;

const BenefitsGrid = ({ items }: BenefitsGridProps): JSX.Element => (
    <Container css={{
        marginTop: '120px',
        marginBottom: '136px',
        [breakpoints.mb]: {
            marginTop: '40px',
            marginBottom: '40px',
        },

    }}
    >
        <Row css={{
            rowGap: '58px',
            [breakpoints.mb]: {
                rowGap: '24px',
            },
        }}
        >
            {items && items.map(item => (
                <Col breakpoints={{ dt: { span: 6 } }}>
                    <SolutionsCard key={item.title} {...item} />
                </Col>
            ))}
        </Row>
    </Container>
);

export default BenefitsGrid;

export const query = graphql`
    fragment BenefitsGridProps on DatoCmsBenefitsGrid {
        items {
            title
            quote {
                blocks
                links
                value
            }
            richText {
                blocks
                links
                value
            }
            link {
                ...LinkWithTitleProps
            }
            image {
                ...DatoCmsInternalAsset
            }
        }
    }
`;
