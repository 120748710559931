import React from 'react';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import HoverItemSet from '../../../primitives/hover-item-set';
import { createLinkWithTitle, LinkWithTitle } from '../../../primitives/link';
import { breakpoints } from '../../../primitives/tokens';

export type PressReleaseSetProps = Queries.PressReleaseSetPropsFragment;

const PressReleaseSet = ({ richTitle, text, pressReleases, link }: PressReleaseSetProps): JSX.Element => (
    <Container css={{
        marginTop: '100px',
        marginBottom: '70px',
        [breakpoints.mb]: {
            marginTop: '56px',
            marginBottom: '38px',
        },
    }}
    >
        <Row css={{
            [breakpoints.mb]: {
                rowGap: '26px',
            },
        }}
        >
            <Col breakpoints={{ dt: { span: 4 } }}>
                <Typography
                    fontSize={{
                        dt: 4453,
                        tb: 3234,
                        mb: 2429,
                    }}
                    css={{
                        [breakpoints.mb]: {
                            textAlign: 'center',
                            br: {
                                content: '""',
                                '&:after': {
                                    content: '" "',
                                },
                            },

                        },
                    }}
                    override
                    fontWeight="light"
                >
                    <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                </Typography>
                <Typography
                    fontSize={{
                        dt: 1822,
                    }}
                    css={{
                        opacity: 0.7,
                        paddingTop: '20px',
                        maxWidth: '280px',
                        [breakpoints.mb]: {
                            textAlign: 'center',
                            maxWidth: '270px',
                            margin: 'auto',
                        },
                    }}
                >
                    {text}
                </Typography>
            </Col>
            <Col breakpoints={{ dt: { span: 8 } }}>
                <HoverItemSet
                    alignRight
                    cards={(pressReleases || []).map(pressRelease => ({
                        title: pressRelease?.title || '',
                        media: pressRelease?.primaryMedia,
                        text: pressRelease?.excerpt || '',
                        link: createLinkWithTitle('Learn More', pressRelease?.permalink),
                    }))}
                    link={link as unknown as LinkWithTitle}
                />
            </Col>
        </Row>
    </Container>
);

export default PressReleaseSet;

export const query = graphql`
    fragment PressReleaseSetProps on DatoCmsPressReleaseSet {
        richTitle {
            blocks
            links
            value
        }
        text
        pressReleases {
            title
            primaryMedia {
                ...MediaItemProps
            }
            permalink
            excerpt
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
