const waitFor = (check: () => boolean, maxIterations = 10): Promise<void> => {
    const internalWaitFor = (
        _check: () => boolean,
        success: () => void,
        failure: (reason: string) => void,
        iteration = 1,
    ): void => {
        if (_check()) {
            success();
        } else if (iteration >= maxIterations) {
            failure('Timeout');
        } else {
            setTimeout(
                () => internalWaitFor(_check, success, failure, iteration + 1),
                100 * iteration,
            );
        }
    };

    return new Promise((resolve, reject) => {
        internalWaitFor(check, resolve, reject);
    });
};

export default waitFor;
