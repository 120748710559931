import { wrap } from '@popmotion/popcorn';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface SliderContext {
    page: number
    direction: number
    paginate: (newDirection: number) => void
    jumpTo: (index: number) => void
}

const SliderContext = React.createContext<SliderContext>(null as unknown as SliderContext);

interface BasicSlider {
    children: React.ReactNode|React.ReactNode[]
    autoPlay?: number
}

interface SingleSlide {
    children: React.ReactNode | React.ReactNode[]
    slidesPerView: number
    setMaxDimensions: (props: { element: HTMLDivElement | null, index: number }) => void
    index: number
    wrappedIndex: number
    animation: 'slide' | 'fade'
    transition: string
    isShowing?: boolean
}

const SingleSlide = ({
    wrappedIndex,
    slidesPerView,
    setMaxDimensions,
    index,
    animation,
    children,
    transition,
    isShowing,
}: SingleSlide): JSX.Element => {
    const target = React.useRef<HTMLDivElement>(null);
    useResizeObserver<HTMLDivElement>(target, (entry) => {
        if (entry) {
            setMaxDimensions({
                element: entry.target as HTMLDivElement,
                index,
            });
        }
    });

    React.useLayoutEffect(() => {
        if (target?.current) {
            setMaxDimensions({
                element: target.current as HTMLDivElement,
                index,
            });
        }
    }, [index, setMaxDimensions, target]);

    return (
        <div
            ref={target}
            /* idx as key to retain the element as the animator to prevent weird jumps */
            /* eslint-disable-next-line react/no-array-index-key */
            css={{
                flex: 'none',
            }}
            style={{
                width: `${100 / slidesPerView}%`,
                opacity: isShowing ? 1 : 0,
                zIndex: (isShowing && animation === 'slide') ? 1 : 0,
                position: index === 0 ? 'relative' : 'absolute',
                // Offset by -100 for the overscan on the left hand side
                transform: animation === 'slide' ? `translateX(${wrappedIndex * 100 - 100}%)` : 'none',
                pointerEvents: isShowing ? 'auto' : 'none',
                transition,
            }}
        >
            {children}
        </div>
    );
};

const BasicSlider = ({ children, autoPlay }: BasicSlider): JSX.Element => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = useCallback((newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    }, [page]);

    const jumpTo = useCallback((newPage: number) => {
        setPage([newPage, 1]);
    }, []);

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (autoPlay) {
            timer = setTimeout(() => {
                paginate(1);
            }, autoPlay * 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [autoPlay, paginate, page]);

    const value = useMemo(() => ({ page, direction, paginate, jumpTo }), [page, direction, paginate, jumpTo]);

    return (
        <SliderContext.Provider value={value}>
            {children}
        </SliderContext.Provider>
    );
};

export const useSliderContext = (): SliderContext => React.useContext(SliderContext);

interface Slides {
    children: React.ReactNode[]
    className?: string
    slidesPerView?: number
    animation?: 'slide' | 'fade'
    transition?: string
}

const Slides = ({ children, className, slidesPerView = 1, animation = 'slide', transition = '0.3s' }: Slides): JSX.Element => {
    const { page } = useSliderContext();

    const slides = React.Children.toArray(children);
    const slideIndex = wrap(0, slides.length, page);

    const [{ height: minHeight, width: minWidth }, setMaxDimensions] = useReducer((
        prev: { height: number, width: number, heightIndex: number, widthIndex: number },
        action: { element: HTMLDivElement | null, index: number },
    ) => {
        /*
        * Screen out 0 index here because it's the 'relative'
        * positioned one and thus the height will always be the
        * container's. The over-scanning gives us the actual height
        * of slide 0 - just rendered later in process.
        *
        * We check if the index is lesser than the last saved to
        * enable resize-observer functionality - this way if page
        * is resized, the slides call setMaxWidth in order and we
        * can work out whether this is a distinct 'event'.
        * */

        if (action.index === 0) {
            return {
                height: 0,
                width: 0,
                heightIndex: 0,
                widthIndex: 0,
            };
        }

        if (action.element && action.index > 0) {
            const updates = {
                height: prev.height,
                heightIndex: prev.heightIndex,
                width: prev.width,
                widthIndex: prev.widthIndex,
            };

            const { height } = action.element.getBoundingClientRect();

            if (prev?.height < height || action.index <= prev.heightIndex) {
                updates.height = height;
                updates.heightIndex = action.index;
            }

            // Width needs to come from children, because the slide ref has width applied (unlike height)
            if (action.element.firstElementChild) {
                const { width } = action.element.firstElementChild.getBoundingClientRect();
                if (prev?.width < width || action.index <= prev.widthIndex) {
                    updates.width = width;
                    updates.widthIndex = action.index;
                }
            }

            return updates;
        }
        return prev;
    }, { height: 0, width: 0, heightIndex: 0, widthIndex: 0 });

    // + 2 for overscan, the indexes of the slides to show in the order of the wrap
    const slidesToShow = [...new Array(slides.length + 2)]
        .map((_, i) => (slideIndex + i + slidesPerView) % slides.length);

    return (
        <div
            css={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                overflow: 'hidden',
            }}
            className={className}
            style={{ minHeight: `${minHeight}px`, minWidth: `${minWidth}px` }}
        >
            {slidesToShow.map((wrappedIndex, idx) => (
                <SingleSlide
                    slidesToShow={slidesToShow.length}
                    slidesPerView={slidesPerView}
                    index={idx}
                    wrappedIndex={wrappedIndex}
                    animation={animation}
                    setMaxDimensions={setMaxDimensions}
                    transition={transition}
                    isShowing={idx === slideIndex}
                >
                    {slides[wrap(0, slides.length, idx)]}
                </SingleSlide>
            ))}
        </div>
    );
};

BasicSlider.Slides = Slides;

export default BasicSlider;
