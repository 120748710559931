import React from 'react';
import { useInView } from 'framer-motion';
import { PolymorphicWithoutRef } from '../../primitives/polymorphic';

interface CascadeChildrenProps {
    delay?: number;
    transitionDuration?: number;
    className?: string;
    childClassName?: string;
    visible?: boolean;
    onComplete?: () => void;
    children: React.ReactElement | React.ReactElement[]
}

const CascadeChildren = <C extends React.ElementType = 'div'>({
    transitionDuration = 500,
    delay = 100,
    visible = true,
    children,
    onComplete,
    className,
    as,
}: PolymorphicWithoutRef<C, CascadeChildrenProps>): JSX.Element => {
    const [maxIsVisible, setMaxIsVisible] = React.useState(0);
    const Component = as || 'div';
    const childCount = React.Children.count(children);
    const ref = React.useRef<any>(null);
    const inView = useInView(ref);

    React.useEffect(() => {
        let count = childCount;
        if (!visible) {
            // Animate all children out
            count = 0;
        }

        if (count === maxIsVisible) {
            // We're done updating maxVisible, notify when animation is done
            const timeout = setTimeout(() => {
                if (onComplete) onComplete();
            }, transitionDuration);
            return () => clearTimeout(timeout);
        }

        // Move maxIsVisible toward count
        const increment = count > maxIsVisible ? 1 : -1;
        const timeout = setTimeout(() => {
            setMaxIsVisible(maxIsVisible + increment);
        }, delay);
        return () => clearTimeout(timeout);
    }, [childCount, delay, maxIsVisible, visible, transitionDuration, onComplete]);

    return (
        <Component ref={ref} className={className}>
            {inView && React.Children.map(children, (child: React.ReactElement, i) => {
                if (child) {
                    return React.cloneElement(child, {
                        style: {
                            ...(child?.props?.style || {}),
                            transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
                            transform: maxIsVisible > i ? 'none' : 'translateY(12px)',
                            opacity: maxIsVisible > i ? 1 : 0,
                        },
                    });
                }
                return null;
            })}
        </Component>
    );
};

export default CascadeChildren;
