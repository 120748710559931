import React from 'react';
import { graphql } from 'gatsby';
import TabletOnlyHero from './tablet-only';
import MediaBelowTextHero from './media-below-text';
import TabletAndMobileHero from './tablet-and-mobile';
import DecoratedMediaItemHero from './decorated-media-item';
import NoVisualHero from './no-visual';
import BlogHero from './blog';
import MobileOnlyHero from './mobile-only';
import TabletHorizontalHero from './tablet-horizontal';
import FramelessDeviceHero from './frameless-device';
import GetStartedFormHero from './get-started-form';

export type HeroProps = Queries.HeroPropsFragment;

const Hero = (props: HeroProps): JSX.Element|null => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props?.visual && props.visual[0]) {
        // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
        const visualTypename = props.visual[0].__typename.replace('DatoCmsHeroVisuals', '');
        switch (visualTypename) {
            case 'GetStartedForm':
                return <GetStartedFormHero {...props} />;
            case 'TabletOnly':
                return <TabletOnlyHero {...props} />;
            case 'MobileOnly':
                return <MobileOnlyHero {...props} />;
            case 'MediaBelowText':
                return <MediaBelowTextHero {...props} />;
            case 'TabletAndMobile':
                return <TabletAndMobileHero {...props} />;
            case 'DecoratedMediaItem':
                return <DecoratedMediaItemHero {...props} />;
            case 'Blog':
                return <BlogHero {...props} />;
            case 'TabletHorizontal':
                return <TabletHorizontalHero {...props} />;
            case 'FramelessDevice':
                return <FramelessDeviceHero {...props} />;
            default:
                return <NoVisualHero {...props} />;
        }
    }
    return <NoVisualHero {...props} />;
};
export default Hero;

export const query = graphql`
    fragment HeroProps on DatoCmsHero {
        id
        richTitle {
            blocks
            links
            value
        }
        text
        buttons {
            __typename
            ... on DatoCmsLinkWithTitle {
                ...LinkWithTitleProps
            }
            ... on DatoCmsVideoPopoverButton {
                ...VideoPopoverButtonProps
            }
        }
        checkmarks {
            text
        }
        visual {
            __typename
            ... on DatoCmsHeroVisualsGetStartedForm {
                successPageTitle
                successPageCopy
                successPageLink {
                    ...LinkWithTitleProps
                }
            }
            ... on DatoCmsHeroVisualsTabletOnly {
                mediaItem {
                    ...MediaItemProps
                }
            }
            ... on DatoCmsHeroVisualsTabletHorizontal {
                mediaItem {
                    ...MediaItemProps
                }
            }
            ... on DatoCmsHeroVisualsMobileOnly {
                mediaItem {
                    ...MediaItemProps
                }
            }
            ... on DatoCmsHeroVisualsMediaBelowText {
                mediaItem {
                    ...MediaItemProps
                }
            }
            ... on DatoCmsHeroVisualsTabletAndMobile {
                tabletMediaItem {
                    ...MediaItemProps
                }
                mobileMediaItem {
                    ...MediaItemProps
                }
            }
            ... on DatoCmsHeroVisualsDecoratedMediaItem {
                mediaItem {
                    ...MediaItemWithDecorationProps
                }
            }
            ...on DatoCmsHeroVisualsBlog {
                background
            }
            ... on DatoCmsHeroVisualsFramelessDevice {
                mediaItem {
                    ...MediaItemProps
                }
                device
            }
        }
    }
`;
