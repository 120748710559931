import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { breakpoints } from '../../../primitives/tokens';
import Col from '../../../primitives/grid/col';
import Row from '../../../primitives/grid/row';

export type TextBlockProps = Queries.TextBlockPropsFragment;

const TextBlock = ({
    richTextBlock,
}: TextBlockProps): JSX.Element => (
    <Container css={{
        marginTop: '80px',
        marginBottom: '80px',
        [breakpoints.tb]: {
            marginTop: '54px',
            marginBottom: '54px',
        },
        [breakpoints.mb]: {
            marginBottom: '39px',
            marginTop: '39px',
        },
    }}
    >
        <div css={{
            margin: '100px auto',
            maxWidth: '800px',
            [breakpoints.mb]: {
                marginTop: '0',
                marginBottom: '32px',
            },
        }}
        >
            <CustomStructuredTextRenderer isBlog data={richTextBlock} />
        </div>
    </Container>
);

export default TextBlock;

export const query = graphql`
    fragment TextBlockProps on DatoCmsTextBlock {
        richTextBlock: richText {
            blocks {
                __typename
                ...MediaItemProps
                ...InlineCtaProps
            }
            links
            value
        }
    }
`;
