import { graphql } from 'gatsby';
import IntegrationsGrid from '../benefits-grid';

export type BenefitsGridProps = Queries.BenefitsGridPropsFragment;
export default IntegrationsGrid;

export const query = graphql`
    fragment IntegrationsGridProps on DatoCmsIntegrationsGrid {
        items {
            title
            quote {
                blocks
                links
                value
            }
            richText {
                blocks
                links
                value
            }
            link {
                ...LinkWithTitleProps
            }
            image {
                ...DatoCmsInternalAsset
            }
        }
    }
`;
