import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { colors } from '../colors';
import { borderRadius, breakpoints } from '../tokens';
import CapterraBadge from './capterra-badge.svg';

const QualityBadges = (): JSX.Element => (
    <div css={{
        backgroundColor: colors.shadesWhite,
        border: colors.grey100,
        boxShadow: '0 24px 34px rgba(0, 0, 0, 0.12)',
        borderRadius: borderRadius.full,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 18px',
        gap: '14px',
    }}
    >
        <StaticImage
            css={{
                width: '110px',
                objectFit: 'contain',
            }}
            src="iso-badge.png"
            objectFit="contain"
            alt="ISO-27001"
        />
        <CapterraBadge css={{
            width: '130px',
            padding: '10px',
            [breakpoints.mb]: {
                padding: '4px',
            },
        }}
        />
    </div>
);

export default QualityBadges;
