import { graphql, useStaticQuery } from 'gatsby';

const useDatoRouter = () => {
    const data = useStaticQuery(graphql`
        {
            allDatoCmsPage {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsArticle {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsArticleTag {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsCaseStudy {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsComparison {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsJob {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
            allDatoCmsPolicy {
                nodes {
                    id
                    permalink
                    linkLocales
                }
            }
        }
    `);

    const allAvailableRoutes = Object.fromEntries(Object.values(data).reduce((acc, val) => [
        ...acc,
        ...val.nodes.map(x => [x.permalink.split('/').filter(y => !!y).join('/'), { permalink: x.permalink, locales: x.linkLocales }]),
        ...val.nodes.map(x => [x.originalId, { permalink: x.permalink, locales: x.linkLocales }]),
    ], []));

    const getRoute = (linkOrId, language, prescriptive = false) => {
        const replaceRoute = (() => {
            if (linkOrId.startsWith('/au')) {
                return linkOrId.replace('/au', '');
            }
            if (linkOrId.startsWith('/uk')) {
                return linkOrId.replace('/uk', '');
            }
            if (linkOrId.startsWith('/us')) {
                return linkOrId.replace('/us', '');
            }
            return linkOrId;
        })();
        let matchedRoute = allAvailableRoutes[replaceRoute.split('/').filter(x => !!x).join('/')];

        if (['/au', '/uk', '/us'].includes(linkOrId)) {
            matchedRoute = allAvailableRoutes[''];
        }

        if (matchedRoute) {
            const foundWithLocale = matchedRoute.locales.includes(language);
            if (foundWithLocale) {
                return `${`/${language}`}${matchedRoute.permalink === '/' ? '' : matchedRoute.permalink}` || '/';
            }
            if (!prescriptive) {
                return matchedRoute.permalink;
            }
        }
        if (prescriptive) {
            return null;
        }

        return linkOrId;
    };

    return getRoute;
};

export default useDatoRouter;
