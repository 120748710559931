import React from 'react';
import { Helmet } from 'react-helmet';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import { colors } from '../../../primitives/colors';
import { breakpoints } from '../../../primitives/tokens';

const BlogHero = (props: HeroProps): JSX.Element => {
    const { visual } = props;
    return (
        <Background
            css={{
                zIndex: 1,
                marginTop: (() => {
                    if (visual && visual[0] && visual[0].background === 'orange') {
                        return '-106px';
                    }
                    return undefined;
                })(),
                paddingTop: (() => {
                    if (visual && visual[0] && visual[0].background === 'orange') {
                        return '106px';
                    }
                    return undefined;
                })(),
                position: 'relative',
            }}
            breakpoints={{
                dt: {
                    gradient: (visual && visual[0] && visual[0].background === 'orange') ? 'orangeReverse' : undefined,
                },
            }}
        >
            {(visual && visual[0] && visual[0].background === 'orange') && (
                <Helmet
                    htmlAttributes={typeof window === 'undefined' ? {
                        style: {
                            '--header-color-1': colors.shadesWhite,
                            '--header-color-2': colors.shadesWhite,
                            '--header-color-3': '#fff4',
                            '--header-color-4': 'transparent',
                            '--header-color-5': 'transparent',
                            '--header-color-6': colors.shadesWhite,
                            '--header-color-7': '#fff6',
                        },
                    } : {
                        // eslint-disable-next-line max-len
                        style: `--header-color-1: ${colors.shadesWhite}; --header-color-2:${colors.shadesWhite}; --header-color-3:#fff4; --header-color-4:transparent; --header-color-5: transparent; --header-color-6: ${colors.shadesWhite}; --header-color-7: #fff6`,
                    }}
                />
            )}
            <Container>
                <Row>
                    <Col breakpoints={{ dt: { span: 12 } }}>
                        <div css={{
                            paddingTop: '100px',
                            paddingBottom: (visual && visual[0] && visual[0].background === 'orange') ? '80px' : '40px',
                            margin: 'auto',
                            color: (visual && visual[0] && visual[0].background === 'orange') ? colors.shadesWhite : colors.primaryDarkPurple,
                            [breakpoints.mb]: {
                                paddingTop: '60px',
                                paddingBottom: (visual && visual[0] && visual[0].background === 'orange') ? '60px' : '0',
                            },
                        }}
                        >
                            <Content centered {...props} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Background>
    );
};
export default BlogHero;
