import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import AccordionArrow from './accordion-arrow.svg';
import { colors } from '../colors';
import { breakpoints } from '../tokens';

type AccordionOpenState = 'open' | 'opening' | 'closing' | 'closed';
interface AccordionContext {
    isOpen: boolean
    trigger: (e: React.MouseEvent) => void
}

const AccordionContext = React.createContext<AccordionContext>({ isOpen: false, trigger: () => {} });

const Accordion = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => {
    const ref = React.useRef<HTMLElement & { open: boolean }>(null);
    const animationRef = React.useRef<Animation|null>(null);
    const stateRef = React.useRef<AccordionOpenState>('closed');
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const onAnimationFinish = (state: AccordionOpenState): void => {
        if (ref?.current) {
            if (state === 'closed') {
                stateRef.current = 'closed';
                ref.current.open = false;
            }
            if (state === 'open') {
                stateRef.current = 'open';
            }
            ref.current.style.overflow = 'unset';
            ref.current.style.height = 'unset';
        }
        if (animationRef?.current) {
            animationRef.current = null;
        }
    };

    const expand = React.useCallback(() => {
        if (ref?.current) {
            const summaryEl = ref.current.querySelector('summary');
            const contentEl = ref.current.querySelector('.accordion-content');

            if (summaryEl && contentEl) {
                setIsOpen(true);

                stateRef.current = 'opening';
                const startHeight = `${ref.current.offsetHeight}px`;

                // Calculate the open height of the element (summary height + content height)
                const endHeight = `${(summaryEl as HTMLElement).offsetHeight + (contentEl as HTMLDivElement).offsetHeight}px`;

                // If there is already an animation running
                if (animationRef.current) {
                    // Cancel the current animation
                    animationRef.current.cancel();
                }

                // Start a WAAPI animation
                animationRef.current = ref.current.animate({
                    // Set the keyframes from the startHeight to endHeight
                    height: [startHeight, endHeight],
                }, {
                    duration: 400,
                    easing: 'ease-out',
                });
                // When the animation is complete, call onAnimationFinish()
                animationRef.current.onfinish = () => onAnimationFinish('open');
            }
        }
    }, []);

    const open = React.useCallback(() => {
        if (ref?.current) {
            ref.current.style.height = `${ref.current.offsetHeight}px`;
            // Force the [open] attribute on the details element
            ref.current.open = true;
            // Wait for the next frame to call the expand function
            window.requestAnimationFrame(() => expand());
        }
    }, [expand]);

    const close = React.useCallback(() => {
        if (ref?.current) {
            const summaryEl = ref.current.querySelector('summary');

            if (summaryEl) {
                setIsOpen(false);
                stateRef.current = 'closing';
                const startHeight = `${ref.current.offsetHeight}px`;

                const endHeight = `${summaryEl.offsetHeight}px`;
                if (animationRef.current) {
                    animationRef.current.cancel();
                }
                animationRef.current = ref.current.animate({
                    // Set the keyframes from the startHeight to endHeight
                    height: [startHeight, endHeight],
                }, {
                    duration: 400,
                    easing: 'ease-out',
                });
                animationRef.current.onfinish = () => onAnimationFinish('closed');
            }
        }
    }, []);

    const trigger = React.useCallback((e: React.MouseEvent): void => {
        e.preventDefault();
        if (ref?.current) {
            ref.current.style.overflow = 'hidden';
            if (stateRef.current === 'closing' || stateRef.current === 'closed') {
                open();
            } else if (stateRef.current === 'opening' || stateRef.current === 'open') {
                close();
            }
        }
    }, [close, open]);

    const value = React.useMemo(() => ({ isOpen, trigger }), [isOpen, trigger]);

    return (
        <AccordionContext.Provider value={value}>
            <details
                css={{
                    '&[open] .accordion-icon': {
                        transform: 'rotate(180deg)',
                    },
                    summary: {
                        listStyleType: 'none',
                    },
                    'summary::-webkit-details-marker': {
                        display: 'none',
                    },
                }}
                ref={ref}
                className={className}
            >
                {children}
            </details>
        </AccordionContext.Provider>
    );
};

type Children = React.ReactNode | React.ReactNode[] | string;

interface AccordionButtonProps {
    className?: string
    children: Children | ((open: boolean) => Children)
}

export const AccordionButton = ({ children, className }: AccordionButtonProps): JSX.Element => {
    const { trigger, isOpen } = React.useContext(AccordionContext);

    let localChildren;
    if (typeof children === 'function') {
        localChildren = children(isOpen);
    } else {
        localChildren = children;
    }

    return (
        <summary css={{ cursor: 'pointer' }} className={className} onClick={trigger}>
            <div css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            >
                {localChildren}
            </div>
        </summary>
    );
};

export const AccordionPanel = ({ children }: PropsWithChildren): JSX.Element => (
    <div className="accordion-content">{children}</div>
);

/**
 * This is an open/close caret icon that will rotate when the accordion
 * is opened or closed.
 * This must be used within an <AccordionItem /> instance.
 * @alias AccordionIconProps
 * */
export const AccordionIcon = ({
    className,
    disableRotate,
    ...props
}: { disableRotate?: boolean } & React.HTMLProps<
SVGSVGElement
>): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AccordionArrow
        css={{
            transition: '0.3s',
            flex: 'none',
            color: colors.primaryDarkPurple,
            [breakpoints.mb]: {
                width: '8px',
            },
        }}
        className={clsx('accordion-icon', className)}
        {...props}
    />
);

AccordionIcon.displayName = 'AccordionIcon';

export default Accordion;
