import React from 'react';
import { useDebug } from './debug-provider';
import Row from '../primitives/grid/row';
import Col from '../primitives/grid/col';
import Container from '../primitives/grid/container';
import { useGridContext } from '../primitives/grid/context';
import useBreakpointValue from '../primitives/grid/use-breakpoint-value';

const GridLinesDebug = (): JSX.Element | null => {
    const { showGridLines } = useDebug();
    const { sortedBreakpoints, desktopFirst } = useGridContext();
    const cols = useBreakpointValue({
        items: (() => sortedBreakpoints.map(x => ({ query: x[1].query, value: x[1].columns })))(),
        defaultValue: 12,
        desktopFirst,
    });

    if (showGridLines) {
        return (
            <div css={{
                position: 'fixed',
                pointerEvents: 'none',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '100',
            }}
            >
                <Container css={{ height: '100%', width: '100%', position: 'absolute', margin: 'auto' }}>
                    <Row css={{ height: '100%' }}>
                        {Array.from([...new Array(cols)]).map((_, idx) => (
                            <Col
                                breakpoints={{ dt: { span: 1 } }}
                                css={{
                                    backgroundColor: 'green',
                                    opacity: 0.3,
                                }}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
        );
    }

    return null;
};

export default GridLinesDebug;
