import React from 'react';
import { PolymorphicWithoutRef } from '../polymorphic';

export const colors = {

    primaryOrange: 'var(--color-primary-orange)',
    primaryLightOrange: 'var(--color-primary-light-orange)',
    primaryPurple: 'var(--color-primary-purple)',
    primaryLightPurple: 'var(--color-primary-light-purple)',
    primaryDarkPurple: 'var(--color-primary-dark-purple)',

    primaryPurpleHover: 'var(--color-primary-purple-hover)',
    primaryOrangeHover: 'var(--color-primary-orange-hover)',
    primaryLightOrangeHover: 'var(--color-primary-light-orange-hover)',

    secondaryOrange: 'var(--color-secondary-orange)',

    shadesBlack: 'var(--color-shades-black)',
    shadesWhite: 'var(--color-shades-white)',

    statesError: 'var(--color-states-error)',
    statesSuccess: 'var(--color-states-success)',
    statesFocus: 'var(--color-states-focus)',

    grey50: 'var(--color-grey-50)',
    grey100: 'var(--color-grey-100)',
    grey200: 'var(--color-grey-200)',

};

export type ColorProps = {
    color?: keyof typeof colors,
    backgroundColor?: keyof typeof colors,
};

const Color = <C extends React.ElementType = 'div'>({
    color,
    backgroundColor,
    as,
    ...props
}: PolymorphicWithoutRef<C, ColorProps>): JSX.Element => {
    const Component = as || 'div';
    return (
        <Component
            {...props}
            css={{
                color: (() => {
                    if (color && colors[color]) {
                        return colors[color];
                    }
                    return undefined;
                })(),
                backgroundColor: (() => {
                    if (backgroundColor && colors[backgroundColor]) {
                        return colors[backgroundColor];
                    }
                    return undefined;
                })(),
            }}
        />
    );
};
export default Color;
