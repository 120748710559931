import React from 'react';
import { useIsomorphicLayoutEffect, useSpring, useTransform, useViewportScroll, motion } from 'framer-motion';
import { getPositionOfElement } from '../utils/get-position-of-element';
import { breakpoints } from '../../primitives/tokens';

const useSSRSpring = (transform, physics, initialValue) => {
    const [hasRenderedOnce, setHasRenderedOnce] = React.useState(false);
    const progress = useSpring(transform, physics);

    React.useEffect(() => {
        setTimeout(() => {
            setHasRenderedOnce(true);
        }, 500);
    }, []);

    if (!hasRenderedOnce) {
        // @ts-ignore
        progress.current = progress.prev = initialValue;
        progress.set(initialValue);
    }

    return progress;
};

interface ParallaxProps extends React.HTMLProps<'div'> {
    x?: number[]
    y?: number[]
}

const getViewportWidth = (): number => {
    if (typeof window !== 'undefined') {
        return Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0,
        );
    }
    return 0;
};

const getViewportHeight = (): number => {
    if (typeof window !== 'undefined') {
        return Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0,
        );
    }
    return 0;
};
const Parallax = ({
    x = [0, 0],
    y = [0, 0],
    children,
    ...props
}: ParallaxProps): JSX.Element => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const [pageTop, setPageTop] = React.useState(0);
    const [pageBottom, setPageBottom] = React.useState(0);

    const doCalc = (): void => {
        if (containerRef?.current) {
            const containerPos = getPositionOfElement(containerRef);
            if (containerPos) {
                setPageTop(containerPos.pageTop);
                setPageBottom(containerPos.pageBottom);
            }
        }
    };

    const { scrollY } = useViewportScroll();

    const transform = useTransform(
        scrollY,
        [Math.max(pageTop - getViewportHeight(), 0), pageBottom],
        [y[0], y[1]],
    );

    const transformX = useTransform(
        scrollY,
        [Math.max(pageTop - getViewportHeight(), 0), pageBottom],
        [x[0], x[1]],
    );

    const physics = { damping: 30, mass: 0.05, stiffness: 200 }; // easing of smooth scroll
    const progress = useSSRSpring(transform, physics, y[0]);

    const progressX = useSSRSpring(transformX, physics, x[0]);

    useIsomorphicLayoutEffect(() => {
        doCalc();
    }, [containerRef, pageTop, pageBottom]);

    return (
        <div ref={containerRef} {...props}>
            <motion.div
                style={{ x: progressX, y: progress }}
                css={{
                    transform: `translateY(${y[0]}px) translateX(${x[0]}px)`,
                    height: '100%',
                    width: '100%',
                    [breakpoints.mb]: {
                        transform: 'none!important',
                        opacity: '1!important',
                    },
                }}
            >
                {children}
            </motion.div>
        </div>
    );
};

export default Parallax;
