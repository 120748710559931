import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
// eslint-disable-next-line import/no-cycle
import { useLocation, navigate } from '@reach/router';
import { useLanguage } from './language-provider';
import { trackLanguageSwitch } from '../../util/analytics';
import Select, { SelectOption } from '../primitives/select';
import { smallTextStyles } from '../primitives/typography';
import { colors } from '../primitives/colors';
import ScrollEffect from '../animations/components/scroll-effect';
import useDatoRouter from './use-dato-router';

const LanguageSwitcher = (): JSX.Element => {
    const { languageMenu, language } = useLanguage();
    const getPath = useDatoRouter();
    const { pathname } = useLocation();

    const onChange = (lang: string): void => {
        trackLanguageSwitch(lang);
        navigate(getPath(`/${pathname.split('/').filter(x => !!x).join('/')}`, lang))
    };

    return (
        <ScrollEffect>
            <Select
                name="change-language"
                value={language}
                onChange={(langKey) => {
                    onChange(langKey);
                }}
                bottomMargin="36px"
                css={{
                    ...smallTextStyles,
                    border: 'none',
                    padding: '11px 0',
                    backgroundColor: 'transparent',
                    color: colors.shadesWhite,
                } as Interpolation<Theme>}
            >
                {languageMenu.sort(
                    (x, y) => (y.selected ? 1 : 0) - (x.selected ? 1 : 0),
                ).map(({ langKey, name }) => (
                    <SelectOption value={langKey}>
                        {name}
                    </SelectOption>
                ))}

            </Select>
        </ScrollEffect>
    );
};

export default LanguageSwitcher;
