import React from 'react';
import { graphql, Script } from 'gatsby';
import waitFor from '../../../../util/wait-for';
import Container from '../../../primitives/grid/container';
import { getLink } from '../../../primitives/link';

type HubspotFormProps = Queries.HubspotFormPropsQuery;

type AttachedWindow = Window & { jQuery?: any, hbspt?: any };

const HubspotForm = ({ formId, onSuccessPage }: HubspotFormProps): JSX.Element => {
    const id = React.useId();

    React.useEffect(() => {
        // jQuery shim which lets the onFormSubmit work
        (window as AttachedWindow).jQuery = (window as AttachedWindow).jQuery
            || (() => ({
                change: () => {},
                trigger: () => {},
            }));

        if (document.getElementById(`form_${id.replaceAll(':', '')}`)?.children?.length === 0) {
            waitFor(() => !!(window as AttachedWindow)?.hbspt?.forms?.create, 100)
                .then(() => {
                    (window as AttachedWindow).hbspt.forms.create({
                        portalId: '4454113',
                        formId,
                        target: `#form_${id.replaceAll(':', '')}`,
                        onFormSubmit: () => {
                            setTimeout(() => {
                                window.location.href = getLink(onSuccessPage[0].link)?.link;
                            }, 500);
                        },
                    });
                    return null;
                })
                .catch((err) => {
                    console.error('Error occurred loading hbspt form:', err);
                });
        }
    }, [formId, id, onSuccessPage]);

    return (
        <Container css={{
            padding: '100px 0',
        }}
        >
            <Script src="//js.hsforms.net/forms/shell.js" strategy="post-hydrate" />
            <div css={{ height: '100%' }} id={`form_${id.replaceAll(':', '')}`} />
        </Container>
    );
};

export default HubspotForm;

export const query = graphql`
    fragment HubspotFormProps on DatoCmsHubspotForm {
        formId
        onSuccessPage {
            ...LinkWithoutTitleProps
        }
    }
`;
